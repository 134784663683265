
.card {
    height: 2.5 em;
    width: 2.5 em;
    border: 2px solid red;
    box-shadow: .1em .1em rgb(52, 25, 27);
    border-radius: 5%;
}
h3{
    font-size: medium;
}
.card:hover{
    transform: scale(1.1);
}